<script setup>
/******************************************************************************
 * Component: Datepicker / Calendar
 *****************************************************************************/
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import DatePicker from "vue-flatpickr-component";
import { German } from "flatpickr/dist/l10n/de.js";
import { ref, onMounted } from "vue";
const props = defineProps({
    lowerLimit: {
        type: Date,
        default: 21,
    },
    upperLimit: {
        type: Date,
        default: 0,
    },
    initialDate: {
        type: String,
        required: true,
    },
    forId: {
        type: String,
        required: true,
    },
});
const selectedDate = ref("");
const config = {
    dateFormat: "d.m.Y",
    minDate: props.lowerLimit,
    maxDate: props.upperLimit,
    locale: German,
    onChange: () => {
        emit("change", selectedDate.value);
    },
};
const emit = defineEmits(["change"]);
onMounted(() => {
    selectedDate.value = props.initialDate;
});
</script>

<template>
    <date-picker
        :id="forId"
        v-model="selectedDate"
        class="form-input form-input--has-addon"
        :config="config"
    />
    <label class="form-input-addon" :for="forId">
        <svg-icon name="calendar" />
    </label>
</template>

<style lang="css">
@import "flatpickr/dist/flatpickr.css";
</style>

<style lang="scss">
$inset: get($client, "appInsetShadowColor");

/* stylelint-disable selector-class-pattern */
.flatpickr-calendar.flatpickr-calendar {
    width: 316px;
    padding: get($sizes, "border");
    border: get($client, "inputBorder");
    margin-top: 4px;

    box-shadow: inset #{get($sizes, "border")} #{get($sizes, "border")} 0 $inset,
        inset #{get($sizes, "border")} -#{get($sizes, "border")} 0 $inset,
        inset -#{get($sizes, "border")} #{get($sizes, "border")} 0 $inset,
        inset -#{get($sizes, "border")} -#{get($sizes, "border")} 0 $inset;

    &::before {
        bottom: calc(100% + 2px);

        width: 0;
        height: 0;
        border-width: 0 5px 5px;
        margin: 0;

        border-right-color: transparent;
        border-bottom-color: get($client, "inputBorderColor");
        border-left-color: transparent;
    }

    &::after {
        display: none;
    }

    .flatpickr-rContainer {
        width: 100%;
    }

    .flatpickr-months {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;

        background-color: get($client, "datePickerHeadBg");

        .flatpickr-prev-month,
        .flatpickr-next-month,
        .flatpickr-month,
        .flatpickr-current-month input.cur-year {
            fill: get($client, "datePickerHeadColor");
            color: get($client, "datePickerHeadColor");
        }

        .flatpickr-prev-month:hover,
        .flatpickr-next-month:hover {
            background: palette("grey", "bunker");

            > svg {
                fill: palette("text", "bright");
            }
        }

        .flatpickr-current-month.flatpickr-current-month {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;

            select option {
                background-color: get($client, "datePickerHeadBg");
            }
        }

        .flatpickr-prev-month,
        .flatpickr-next-month {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .flatpickr-weekdays {
        background-color: get($client, "datePickerWeekdayBg");
        color: get($client, "datePickerWeekdayColor");
    }

    .flatpickr-days {
        width: 100%;
        max-width: 100%;
    }

    .dayContainer {
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        width: 100%;
        max-width: 100%;
        margin-top: 2px;
        grid-gap: 2px;
    }

    .flatpickr-day {
        width: auto;
        max-width: 100%;
        border: 1px solid transparent;

        background-color: get($client, "datePickerDayBg");
        color: get($client, "datePickerDayColor");
        border-radius: get($sizes, "border");

        &:not(.flatpickr-disabled):hover {
            background-color: get($client, "dataPickerDayHoverBg");
            color: get($client, "dataPickerDayHoverColor");
        }

        &.prevMonthDay,
        &.nextMonthDay {
            background-color: get($client, "datePickerDayOtherMonthBg");
            color: get($client, "datePickerDayOtherMonthColor");
        }

        &.flatpickr-disabled {
            background-color: get($client, "datePickerDayDisabledBg");
            color: get($client, "datePickerDayDisabledColor");

            font-style: italic;
        }

        &.today {
            border-color: get($client, "datePickerDayTodayBorder");
        }

        &.selected {
            background-color: get($client, "datePickerDaySelectedBg");
            color: get($client, "datePickerDaySelectedColor");
            border-color: get($client, "datePickerDaySelectedBg");
        }
    }
}
/* stylelint-enable selector-class-pattern */
</style>

<style lang="scss" scoped>
.form-input-addon {
    cursor: pointer;
}
</style>
